<template>
  <div class="goods-list">
    <!-- 查詢 -->
    <section class="table-search">
      <div class="search-L">
        <el-input v-model="tableParams.kw" placeholder="请输入商品名称搜索" @input="kwChange" clearable class="mr15" />
      </div>
      <div class="search-R">
        <el-button type="primary" @click="onAdd()">新增商品</el-button>
      </div>
    </section>
    <!-- 表格 -->
    <el-table :data="tableData" border stripe :header-cell-style="{
      background: '#FAFAFA', color: '#000000'
    }">
      <el-table-column label="商品缩略图" align="center">
        <template slot-scope="scope">
          <el-image style="width: .28rem; height: .28rem" :src="scope.row.imageUrl"
            :preview-src-list="[scope.row.imageUrl]">
          </el-image>
        </template>
      </el-table-column>
      <el-table-column label="商品名称" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.goodsName || "-" }}</span>
        </template>
      </el-table-column>
      <!-- <el-table-column label="建议售价" align="center">
        <template slot-scope="scope">
          <span>￥{{ scope.row.retailPrice || "0" }}</span>
        </template>
      </el-table-column> -->
      <el-table-column label="商品库存" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.inventory || "0" }}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <el-button type="text" size="medium" @click.stop="onEdit(scope.row)">编辑</el-button>
          <el-button type="text" size="medium" @click.stop="onInventory(scope.row)">进销损</el-button>
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页 -->
    <section class="table-footer">
      <el-pagination layout="total,sizes, prev, pager, next,jumper" background :total="total"
        :page-size="tableParams.pageSize" @size-change="onSizeChange" :page-sizes="[10, 20, 50, 100]"
        :current-page.sync="currentPage" @current-change="onCurrentPage">
      </el-pagination>
    </section>
    <!-- 编辑弹框 -->
    <el-dialog :title="editTitle" :visible.sync="editShow" :close-on-click-modal="false" append-to-body width="40%">
      <!-- 表单提交 -->
      <el-form :model="params" :rules="rules" ref="form-ref" label-width="100px">
        <section class="form-main">
          <el-form-item label="商品名称" prop="goodsName">
            <el-input v-model.trim="params.goodsName" autocomplete="off" maxlength="6" placeholder="请填写商品名称"
              clearable></el-input>
          </el-form-item>
          <!-- <el-form-item label="建议售价" prop="retailPrice">
            <el-input v-model.trim="params.retailPrice" autocomplete="off" maxlength="5" placeholder="请填写建议售价"
              oninput="value=value.replace(/[^\d.]/g,'')" clearable>
              <template slot="prepend">￥</template>
            </el-input>
          </el-form-item> -->
          <!-- 商品缩略图 -->
          <el-form-item label="商品缩略图" prop="imageId">
            <div class="upload-btn" @click="onUpload(1, 1)">
              <img v-if="params.imageUrl" :src="params.imageUrl" class="upload-img">
              <i v-else class="el-icon-plus uploader-icon"></i>
            </div>
          </el-form-item>
          <!-- 商品主图 -->
          <el-form-item label="商品主图" prop="imageIds" class="long-width">
            <ul class="imgs">
              <li v-for="item in fileList" class="upload-img">
                <img :src="item.url">
                <div class="img-del" @click="onImgDel(item)">
                  <i class="el-icon-delete"></i>
                </div>
              </li>
              <li class="upload-btn" v-show="upBtnShow">
                <i class="el-icon-plus uploader-icon" @click="onUpload(2, 2)"></i>
              </li>
            </ul>
          </el-form-item>
          <!-- 富文本 -->
          <el-form-item label="商品详情" prop="goodsDetail" class="long-width">
            <quill-editor v-model="params.goodsDetail" ref="myQuillEditor" :options="editorOption"
              @change="onEditorChange1($event)">
            </quill-editor>
          </el-form-item>
          <el-form-item label="使用说明" prop="instructions" class="long-width">
            <quill-editor v-model="params.instructions" ref="myQuillEditor" :options="editorOption"
              @change="onEditorChange2($event)">
            </quill-editor>
          </el-form-item>
        </section>
        <section class="form-footer">
          <el-button type="primary" @click="onSubmit('form-ref')">保存</el-button>
        </section>
      </el-form>
    </el-dialog>
    <!-- 进销损弹框 -->
    <el-dialog title="进销损处理" :visible.sync="inventoryShow" :close-on-click-modal="false" append-to-body width="30%">
      <el-form :model="inventoryParams" :rules="rules" ref="form-inventory" label-width="100px">
        <section class="form-main">
          <el-form-item label="选择类型" prop="inventoryType">
            <el-radio-group v-model="inventoryParams.inventoryType">
              <el-radio :label="1">进货</el-radio>
              <el-radio :label="2">销货</el-radio>
              <el-radio :label="3">损耗</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item :label="countTitle" prop="inventory">
            <el-input v-model.trim="inventoryParams.inventory" autocomplete="off" maxlength="5" placeholder="请填写进销损数量"
              oninput="value=value.replace(/[^\d]/g,'')" clearable>
            </el-input>
          </el-form-item>
          <el-form-item label="当前库存" prop="inventoryType">
            {{ inventoryParams.inventoryNow }}
          </el-form-item>
        </section>
        <section class="form-footer">
          <el-button type="primary" @click="onInventorySubmit('form-inventory')">保存</el-button>
        </section>
      </el-form>
    </el-dialog>
    <!-- 图片上传组件 -->
    <UpLoad @fileIds="getFileIds" :maxlimit="maxlimit" ref="up" :fileType="fileType"></UpLoad>
  </div>
</template>

<script>
import {
  getGoodsList,
  getGoodsAdd,
  getGoodsEdit,
  getGoodsInfo,
  getInventoryEdit,
} from "@/api/point/goods";
import { ObjToOpt } from "@/utils/utils";
import { rules } from "@/db/rules";
import UpLoad from "@/components/upload";
import {
  getFileList
} from "@/api/common";

import { quillEditor } from 'vue-quill-editor';
import 'quill/dist/quill.core.css';
import 'quill/dist/quill.snow.css';
import 'quill/dist/quill.bubble.css';

import * as Quill from 'quill';
import ImageResize from 'quill-image-resize-module';
Quill.register('modules/imageResize', ImageResize);

export default {
  components: { UpLoad, quillEditor },
  data() {
    return {
      ObjToOpt,
      // table表格
      currentPage: 1, // 当前页
      total: 0, // 总条数  
      tableData: [],//表格数据
      tableParams: {
        page: 1,
        pageSize: 10,
        kw: "",//查询关键字
      },
      // 弹框
      editShow: false, //弹框开关
      editTitle: "",//弹框标题
      // from表单
      rules,
      params: {
        goodsName: "",
        retailPrice: "",
        imageId: "",
        imageUrl: "",
        imageIds: "",
        goodsDetail: "",
      },
      fileList: [],//图片列表
      fileType: 1,
      upType: "",
      editorOption: {
        modules: {
          toolbar: {
            container: [
              ['bold', 'italic', 'underline', 'strike'], // 加粗 斜体 下划线 删除线
              ['blockquote', 'code-block'], // 引用  代码块
              [{ header: 1 }, { header: 2 }], // 1、2 级标题
              [{ list: 'ordered' }, { list: 'bullet' }], // 有序、无序列表
              [{ script: 'sub' }, { script: 'super' }], // 上标/下标
              [{ indent: '-1' }, { indent: '+1' }], // 缩进
              [{ direction: 'rtl' }], // 文本方向
              [{ size: ['12', '14', '16', '18', '20', '22', '24', '28', '32', '36'] }], // 字体大小
              [{ header: [1, 2, 3, 4, 5] }], // 标题
              [{ color: [] }, { background: [] }], // 字体颜色、字体背景颜色
              [{ align: [] }], // 对齐方式
              ['clean'], // 清除文本格式
              ['image', 'video'] // 链接、图片、视频
            ],
            handlers: {
              'image': (value) => {
                if (value) {
                  this.onUpload(1, 3);
                } else {
                  this.quill.format('image', false)
                }
              }
            }
          },
          // 调整图片大小
          imageResize: {
            displayStyles: {
              backgroundColor: 'black',
              border: 'none',
              color: 'white'
            },
            modules: ['Resize', 'DisplaySize', 'Toolbar']
          }
        },
        placeholder: '请填写正文'
      },
      // 进销损
      inventoryShow: false,
      inventoryParams: {
        inventoryType: 1,
        inventory: "",
        inventoryNow: "",
      }
    }
  },
  computed: {
    upBtnShow() {
      if (this.fileList && this.fileList.length > 5) {
        return false
      } else if (!this.fileList || this.fileList.length < 5) {
        return true
      }
    },
    maxlimit() {
      if (this.fileList && this.fileList.length > 0) {
        return 5 - this.fileList.length
      } else {
        return 5
      }
    },
    countTitle() {
      let obj = {
        1: "进货数量",
        2: "销货数量",
        3: "损耗数量"
      }
      return obj[this.inventoryParams.inventoryType]
    }
  },
  // 监听属性  
  watch: {
    fileList(val) {
      
      let arr = [];
      if (val && val.length > 0) {
        for (let i of val) {
          arr = [...arr, i.attId]
        }
      }
      this.params.imageIds = arr.toString()
      
    },
  },
  created() {
    
  },
  mounted() {
    this.getGoodsList();// 【请求】商户商品列表
  },
  methods: {
    // 【请求】商户商品列表
    getGoodsList() {
      let data = this.tableParams;
      getGoodsList(data).then((res) => {
        if (res.isSuccess == "yes") {
          this.tableData = res.data.list;
          this.total = res.data.total;
        }
      });
    },

    // 【请求】新增商户商品
    getGoodsAdd() {
      let data = this.params;
      getGoodsAdd(data).then((res) => {
        if (res.isSuccess == "yes") {
          this.$message({
            message: res.message,
            type: "success",
          });
          this.editShow = false;
          this.reload();// 【请求】表格数据
        }
      });
    },

    // 【请求】查询商户商品详情
    getGoodsInfo(row) {
      getGoodsInfo(row).then((res) => {
        if (res.isSuccess == "yes") {
          this.params = res.data;
          this.fileList = res.data.fileList || [];
        }
      });
    },

    // 【请求】编辑商户商品
    getGoodsEdit() {
      let data = this.params;
      getGoodsEdit(data).then((res) => {
        if (res.isSuccess == "yes") {
          this.$message({
            message: res.message,
            type: "success",
          });
          this.editShow = false;
          this.getGoodsList();// 【请求】表格数据
        }
      });
    },

    // 【请求】编辑商品进销损
    getInventoryEdit() {
      let data = this.inventoryParams;
      getInventoryEdit(data).then((res) => {
        if (res.isSuccess == "yes") {
          this.$message({
            message: res.message,
            type: "success",
          });
          this.inventoryShow = false;
          this.getGoodsList();// 【请求】表格数据
        }
      });
    },

    // 【监听】表格模糊查询
    kwChange: _.debounce(function () {
      this.reload();
    }, 500),

    // 【监听】表格重载
    reload() {
      this.tableParams.page = 1;
      this.currentPage = 1;
      this.getGoodsList();
    },

    // 【监听】表格分页点击
    onCurrentPage(page) {
      this.tableParams.page = page;
      this.getGoodsList();
    },

    // 【监听】表格条数点击
    onSizeChange(pageSize) {
      this.tableParams.pageSize = pageSize;
      this.reload();
    },

    // 【监听】新增
    onAdd() {
      this.editShow = true;
      this.editTitle = "新增商品";
      this.params = {
        goodsId: "",
        goodsName: "",
        retailPrice: "",
        imageId: "",
        imageUrl: "",
        imageIds: "",
        goodsDetail: "",
      };
      this.fileList = [];
      this.$nextTick(() => {
        this.$refs["form-ref"].clearValidate();
      });
    },

    // 【监听】编辑
    onEdit(row) {
      this.editShow = true;
      this.editTitle = "编辑商品";
      this.getGoodsInfo(row);
      this.$nextTick(() => {
        this.$refs["form-ref"].clearValidate();
      });
    },

    // 【监听】文件上传
    onUpload(fileType, upType) {
      this.fileType = fileType;
      this.upType = upType;
      this.$refs.up.onUpload();
    },

    // 【监听】获取上传文件ID集合
    getFileIds(fileIds) {
      let data = {
        fileIds
      }
      getFileList(data).then(res => {
        if (res.isSuccess == "yes") {
          // 缩略图
          if (this.upType == 1) {
            this.params.imageId = fileIds;
            this.params.imageUrl = res.data[0].url;
          }
          // 主图
          else if (this.upType == 2) {
            this.params.imageIds = fileIds;
            this.fileList = [...this.fileList, ...res.data]
          }
          // 富文本
          else if (this.upType == 3) {
            let url = this.cutUrl(res.data[0].url);//获取图片路径
            let quill = this.$refs.myQuillEditor.quill;//获取光标信息
            let length = quill.getSelection().index;//获取当前光标位置
            quill.insertEmbed(length, 'image', url);//在光标处插入图片
            quill.setSelection(length + 1);//将光标移至图片后
          }
          this.$forceUpdate();
        }
      })
    },

    cutUrl(url) {
      let index = url.indexOf('?');
      return url.slice(0, index)
    },

    onImgDel(item) {
      this.$confirm('你确定要删除吗?', '删除', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'error'
      }).then(() => {
        this.fileList.splice(this.fileList.indexOf(item), 1);
      });
    },

    // 【监听】表单提交
    onSubmit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.params.goodsId) {
            console.log('this.params: ', this.params);
            this.getGoodsEdit();
          } else {
            this.getGoodsAdd();
          }
        } else {
          return false;
        }
      });
    },

    // 【监听】富文本内容改变事件
    onEditorChange1({ quill, html, text }) {
      this.params.goodsDetail = html
    },
    onEditorChange2({ quill, html, text }) {
      this.params.instructions = html
    },

    // 【监听】进销损弹窗
    onInventory(row) {
      this.inventoryShow = true;
      this.inventoryParams.goodsId = row.goodsId;
      this.inventoryParams.inventoryNow = row.inventory;
      this.inventoryParams.inventoryType = 1;
      this.inventoryParams.inventory = "";
      this.$nextTick(() => {
        this.$refs["form-inventory"].clearValidate();
      });
    },

    // 【监听】表单提交
    onInventorySubmit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.getInventoryEdit();
        } else {
          return false;
        }
      });
    },
  }
}
</script>

<style lang="scss" scoped>
.goods-list {
  padding: .15rem;
}

.table-footer {
  margin-top: .1rem;
  display: flex;
  justify-content: flex-end;
}

.table-search {
  display: flex;
  justify-content: space-between;
  margin-bottom: .15rem;
}

.search-L {
  display: flex;
}

.form-footer {
  display: flex;
  justify-content: flex-end;
  margin-top: .15rem;
}

.upload-btn {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  width: 1rem;
  height: 1rem;
}

.upload-btn:hover {
  border-color: #409EFF;
}

.uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 1rem;
  height: 1rem;
  line-height: 1rem;
  text-align: center;
}

.upload-img {
  display: inline-block;
  border: 1px dashed #d9d9d9;
  margin: 0 .1rem .1rem 0;
  width: 1rem;
  height: 1rem;
  cursor: pointer;
  position: relative;

  >img {
    display: inline-block;
    width: 100%;
    height: 100%;
  }
}

.tip {
  color: red;
  display: flex;
}

.imgs {
  display: flex;
  flex-wrap: wrap;
}

.img-del {
  width: 1rem;
  height: 1rem;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: absolute;
  margin: auto;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, .5);
  visibility: hidden;

  >.el-icon-delete {
    font-size: 28px;
    color: #fff;
  }
}

.upload-img:hover .img-del {
  visibility: visible;
}
</style>

<style>
.long-width .el-form-item__content {
  width: 6rem;
}

.ql-container {
  min-height: 3rem;
}
</style>